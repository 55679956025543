import * as _crossFetch2 from "cross-fetch";
var _crossFetch = _crossFetch2;
try {
  if ("default" in _crossFetch2) _crossFetch = _crossFetch2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var fetchApi;
if (typeof fetch === "function") {
  if (typeof _global !== "undefined" && _global.fetch) {
    fetchApi = _global.fetch;
  } else if (typeof window !== "undefined" && window.fetch) {
    fetchApi = window.fetch;
  } else {
    fetchApi = fetch;
  }
}
if (typeof window === "undefined" || typeof window.document === "undefined") {
  var f = fetchApi || _crossFetch;
  if (f.default) f = f.default;
  exports.default = f;
  exports = exports.default;
}
export default exports;